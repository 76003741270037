<template>
  <!-- 下载中心 -->
  <div class="download_center">
    <!-- 按钮 -->
    <div>
      <van-button type="default" @click="pptDownload()">PPT下载</van-button>
      <van-button type="default" @click="videoDownload()">视频下载</van-button>
    </div>
    <!-- PPT下载列表 -->
    <div v-if="ShowPPTList" class="video_box_outer">
      <van-checkbox-group v-model="result" ref="checkboxGroup">
        <van-cell
          v-for="(item, index) in list"
          clickable
          :key="index"
          @click="toggle(index)"
          class="cell_box"
        >
          <template #default>
              <div class="video_box">
                <div class="img_box">
                    <van-image width="135" height="82" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                </div>
                <div class="detail_box">
                    <div class="detail">
                        <span class="detail_title">【病例分享】肺癌科研{{item + index}}号</span>
                        <span class="detail_date">2021.08.02</span>
                        <span class="detail_status"><van-icon name="checked" />审核成功</span>
                    </div>
                    <div class="download_icon">
                      <van-checkbox :name="item" ref="checkboxes"></van-checkbox>
                    </div>
                </div>
            </div>
          </template>
        </van-cell>
      </van-checkbox-group>
    </div>
    
    <!-- 视频下载列表 -->
    <div v-if="ShowVedioList"  class="video_box_outer">
      <van-checkbox-group v-model="result" ref="checkboxGroup">
        <van-cell
          v-for="(item, index) in list"
          clickable
          :key="index"
          @click="toggle(index)"
          class="cell_box"
        >
          <template #default>
              <div class="video_box">
                <div class="img_box">
                    <van-image width="135" height="82" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                </div>
                <div class="detail_box">
                    <div class="detail">
                        <span class="detail_title">【病例分享】肺癌科研{{item}}号</span>
                        <span class="detail_date">2021.08.02</span>
                        <span class="detail_status"><van-icon name="checked" />审核成功</span>
                    </div>
                    <div class="download_icon">
                      <van-checkbox :name="item" ref="checkboxes"></van-checkbox>
                    </div>
                </div>
            </div>
          </template>
        </van-cell>
      </van-checkbox-group>
    </div>

    <!-- 全选按钮 -->
    <div class="btn_box_outer">
      <van-cell
        clickable
        @click="checkAll"
        class="checkAll btn_box"
      >
        <span class="check_text">全选</span>
        <template #icon="props">
          <van-checkbox v-model="allcheck"/>
        </template>
      </van-cell>
      <van-button type="info" class="download_btn" @click="goDownload">下载</van-button>
    </div>

    <!-- 下载对话框 -->
    <van-dialog v-model="showdialog" title="请输入电子邮箱地址"  show-cancel-button @confirm="confirm" @cancel='cancel'>
      <van-cell-group>
        <p class="message">系统自动将资料文件发送至您的邮箱</p>
        <van-field
          v-model="value"
          label="email"
          left-icon="smile-o"
          placeholder="请输入电子邮箱地址"
        />
      </van-cell-group>
    </van-dialog>
    <Tabbar :name="'video'"></Tabbar>
  </div>
</template>

<script>
import Tabbar from '@/components/tabbar.vue'
import Vue from 'vue';
import { Popup, Dialog, Toast} from 'vant';
Vue.use(Popup);
Vue.use(Dialog);
Vue.use(Toast);
export default {
  name:"",
  components: {
    Tabbar
  },
  data(){
    return {
      list: ["a","b"],
      result: [],
      show_icon: true,
      radio: "",
      ShowPPTList: true, //ppt列表
      ShowVedioList: false, //视频列表
      checked: false,
      allcheck: false,
      showdialog: false,
      value: ""
    }
  },
  methods: {
    // 点击下载
    goDownload(){
      if(this.result.length === 0){
       Toast('请选择要下载的内容')
       return false
      }
       this.showdialog = true
    },
    confirm(){
      if(!this.value){
        Toast("请输入邮箱地址");
        return false
      }
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if(!reg.test(this.value)){
          Toast("邮箱格式不正确");
          return false
        }
      // this.showdialog = false
      // // console.log(this.value,this.result)
      // sendEmail({
      //    email: this.value,
      //    document_ids : this.result
      // }).then((res)=>{
      //    Toast('已发送，请注意查收。')
      //     this.$wechat.closeWindow()
      // }).catch((err)=>{
      //   Toast(err)
      // })
    },
    cancel(){
      this.value = ''
    },
    // 全选
    checkAll() {
      if(this.allcheck){
        this.result = []
        this.allcheck = !this.allcheck
      }else{
          this.$refs.checkboxGroup.toggleAll(true);
          
      }
      this.allcheck = !this.allcheck
      // this.$refs.checkboxGroup.toggleAll(true);
    },
    toggle(index) {
      // console.log(this.allcheck);
      if(this.allcheck){
        this.allcheck = false;
      }
      this.$refs.checkboxes[index].toggle();
    },
    // 点击ppt下载按钮
    pptDownload(){
      this.ShowPPTList = true;
      this.ShowVedioList = false;
    },
    // 点击视频下载按钮
    videoDownload(){
      this.ShowPPTList = false;
      this.ShowVedioList = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.download_center{
  
  .video_box_outer{
    .cell_box{
      padding: 5px 10px;
    }
    .video_box{
        // background: #ccc;
        margin: 5px;
        display: flex;
        // align-items: center;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
        .img_box{
            display: flex;
            padding: 9px;
        }
        .detail_box{
            display: flex;
            // background: #ccc;
            width: 100%;
            justify-content: space-between;
            .detail{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-evenly;
                .detail_title{
                    font-size: 14px;
                    color: #101010;
                }
                .detail_date{
                    font-size: 12px;
                    color: #101010;
                }
                .detail_status{
                    font-size: 12px;
                    color: #15DB4E;
                    display: flex;
                    align-items: center;
                }
            }
            .download_icon{
                // margin-right: 5px;
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
                align-items: center;
                margin-right: 9px;
                // background: #ccc;
            }
        }
    }
}
  .van-button{
    width: 50%;
    padding: 26px 15px;
    font-size: 16px;
  }
  .btn_box_outer{
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 80px;
    // background: #ccc;
    width: 100%;
    .btn_box{
      display: flex;
      align-items: center;
      // margin-left: 8px;
      align-items: center;
      .check_text{
        font-size: 16px;
        margin-left: 5px;
      }
      
    }
    .download_btn{
      width: 80px;
      height: 30px;
      padding: 0;
      font-size: 14px;
      color: #fff;
      margin-right: 15px;
    }
  }
  
}
</style>